import React, { Component, Fragment } from "react";

// Consts
import { S3_BASE_URL } from "../../../config/constants";

// 3rd party

// components
import Card from "../../Shared/Card";

// cssS
// import './styles.css';
import TeamLogo from "../../Shared/TeamLogo";
import RouteGenerator from "../../../util/route-generator";
import Link from "next/link";
import styled from "styled-components";
import { colors } from "../../../config/styles";

class PlayByPlayNewsCard extends Component {
  getCardBackgroundImage = () => {
    const { data } = this.props;

    if (data.postImage) {
      return {
        backgroundImage: `url(${data.postImage})`,
      };
    }

    if (
      !data.postTitle &&
      data.tournament.tournamentImage &&
      data.tournament.tournamentImage !== "undefined"
    ) {
      return {
        //feed noticias home
        backgroundImage: `url(${`${S3_BASE_URL}/${data.tournament.tournamentImage}`})`,
      };
    }

    return {
      backgroundImage: `url(${S3_BASE_URL}/assets/IMAGEM-PBP.png)`,
    };
  };

  render() {
    const { data } = this.props;

    const teamAMatchScore =
      data.bestOf === 1 ? data.scores[0].scoreA : data.seriesScoreA;
    const teamBMatchScore =
      data.bestOf === 1 ? data.scores[0].scoreB : data.seriesScoreB;

    return (
      <Fragment>
        {!!data.isOver && (
          <Card noPadding>
            <Link
              prefetch={false}
              as={RouteGenerator.getMatchRoute(data)}
              href="/partida/[id]"
              passHref
            >
              <PlayByPlayNewsCardContainer>
                <div>
                  <NewsImage style={this.getCardBackgroundImage()} />

                  <NewsContent>
                    {!!data.isOver && <NewsCategory>Pós Jogo</NewsCategory>}
                    <NewsTitle>
                      {!!data.postTitle && <span>{data.postTitle}</span>}

                      {!data.postTitle && (
                        <span>
                          Acompanhe ao vivo {data.teamA.teamName} vs{" "}
                          {data.teamB.teamName} na{" "}
                          {data.tournament.tournamentName}
                        </span>
                      )}
                    </NewsTitle>
                  </NewsContent>
                </div>
              </PlayByPlayNewsCardContainer>
            </Link>
          </Card>
        )}
        {!!data.isOver && (
          <Link
            prefetch={false}
            passHref
            href="/partida/[id]"
            as={RouteGenerator.getMatchRoute(data)}
          >
            <Match>
              <MatchTeam>{data.teamA.teamName}</MatchTeam>
              <TeamLogo teamId={data.teamA.teamId} size={40} />
              <MatchScore>{teamAMatchScore}</MatchScore>
              <MatchVs>✕</MatchVs>
              <MatchScore>{teamBMatchScore}</MatchScore>
              <TeamLogo teamId={data.teamB.teamId} size={40} />
              <MatchTeam>{data.teamB.teamName}</MatchTeam>
            </Match>
          </Link>
        )}

        {!data.isOver && (
          <Card noPadding isLive>
            <Link
              prefetch={false}
              as={RouteGenerator.getMatchRoute(data)}
              href="/partida/[id]"
              passHref
            >
              <PlayByPlayNewsCardContainer isLive>
                <div>
                  <NewsImage style={this.getCardBackgroundImage()} />

                  <NewsContent isLive>
                    {!data.isOver && (
                      <NewsCategory isLive>Ao Vivo</NewsCategory>
                    )}
                    <NewsTitle isLive>
                      {!!data.postTitle && <span>{data.postTitle}</span>}

                      {!data.postTitle && (
                        <span>
                          Acompanhe ao vivo {data.teamA.teamName} vs{" "}
                          {data.teamB.teamName} na{" "}
                          {data.tournament.tournamentName}
                        </span>
                      )}
                    </NewsTitle>
                  </NewsContent>
                </div>
              </PlayByPlayNewsCardContainer>
            </Link>
          </Card>
        )}
        {!data.isOver && (
          <Link
            prefetch={false}
            passHref
            href="/partida/[id]"
            as={RouteGenerator.getMatchRoute(data)}
          >
            <Match isLive>
              <MatchTeam isLive>{data.teamA.teamName}</MatchTeam>
              <TeamLogo teamId={data.teamA.teamId} size={40} />
              <MatchScore isLive>{teamAMatchScore}</MatchScore>
              <MatchVs isLive>✕</MatchVs>
              <MatchScore isLive>{teamBMatchScore}</MatchScore>
              <TeamLogo teamId={data.teamB.teamId} size={40} />
              <MatchTeam isLive>{data.teamB.teamName}</MatchTeam>
            </Match>
          </Link>
        )}
      </Fragment>
    );
  }
}

const NewsTitle = styled.h2`
  font-size: 20px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 12px;
  font-family: "Roboto", sans-serif;
  color: ${colors.GRAY_DARK};
  transition: all 0.2s;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const PlayByPlayNewsCardContainer = styled.a`
  & > div {
    display: flex;
    min-height: 120px;
    cursor: pointer;
    border-top: ${(props) =>
      props.isLive
        ? `solid 1px ${colors.ERROR_LIGHT}`
        : `solid 1px ${colors.SUCCESS}`};
    border-right: ${(props) =>
      props.isLive
        ? `solid 1px ${colors.ERROR_LIGHT}`
        : `solid 1px ${colors.SUCCESS}`};
    border-left: ${(props) =>
      props.isLive
        ? `solid 1px ${colors.ERROR_LIGHT}`
        : `solid 1px ${colors.SUCCESS}`};
    border-bottom: ${(props) =>
      props.isLive
        ? `solid 0px ${colors.ERROR_LIGHT}`
        : `solid 1px ${colors.SUCCESS}`};
  }

  &:hover ${NewsTitle} {
    color: ${(props) => (props.isLive ? colors.LIVE : colors.SUCCESS)};
  }
`;

const NewsImage = styled.div`
  flex-basis: 35%;
  background: ${colors.GRAY_DARK};
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NewsContent = styled.div`
  flex-basis: 70%;
  padding: 10px 20px;

  background-color: ${(props) =>
    props.isLive ? colors.LIVE_LIGHT : colors.SUCCESS_BRIGHTER};

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }
`;

const NewsCategory = styled.span`
  font-size: 9px;
  text-transform: uppercase;
  color: ${(props) => (props.isLive ? colors.LIVE : colors.SUCCESS)};
  font-weight: bold;
  position: relative;

  @media screen and (max-width: 768px) {
    font-size: 0px;
  }
`;

const Match = styled.a`
  display: flex;
  padding: 10px 5px;
  justify-content: center;
  align-items: center;
  // background: white;
  z-index: 0;
  border-top: 1px solid ${colors.GRAY_LIGHTER};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  color: ${colors.GRAY_DARK};
  cursor: pointer;
  margin-bottom: 24px;
  background-color: ${(props) =>
    props.isLive ? colors.LIVE_BRIGHTER : colors.SUCCESS_LIGHT};

  border-top: ${(props) =>
    props.isLive
      ? `solid 1px ${colors.ERROR_LIGHT}`
      : `solid 0px ${colors.SUCCESS}`};
  border-right: ${(props) =>
    props.isLive
      ? `solid 1px ${colors.ERROR_LIGHT}`
      : `solid 1px ${colors.SUCCESS}`};
  border-left: ${(props) =>
    props.isLive
      ? `solid 1px ${colors.ERROR_LIGHT}`
      : `solid 1px ${colors.SUCCESS}`};
  border-bottom: ${(props) =>
    props.isLive
      ? `solid 1px ${colors.ERROR_LIGHT}`
      : `solid 1px ${colors.SUCCESS}`};

  @media screen and (max-width: 768px) {
    padding: 24px 5px;
  }
`;

const MatchTeam = styled.span`
  margin: 0 8px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.GRAY};

  @media only screen and (max-width: 400px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const MatchScore = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin: 0 8px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const MatchVs = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: ${(props) => (props.isLive ? colors.LIVE : colors.SUCCESS)};
  margin: 0 4px;
`;

export default PlayByPlayNewsCard;
